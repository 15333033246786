import React from 'react';
import "./navbar.css";
import ROUTES from "constants/routes";

export default function NavBar(props) {
  return (
    <nav>
      <div id="headingText">{props.title}</div>

      <div>
        <a href={ROUTES.LANDING}><button>Home</button></a>
        <a href={props.githubUrl || "https://github.com/harryli0088"} target="_blank" rel="noopener noreferrer"><button>Github</button></a>
      </div>
    </nav>
  );
}
