import React from 'react';
// import CodeMirror from "codemirror"
import "codemirror/lib/codemirror.css"
import "codemirror/theme/darcula.css"
// import "codemirror/addon/selection/active-line.js"
// import "codemirror/addon/edit/matchbrackets.js"
// import "codemirror/mode/javascript/javascript.js"
import "./modelSimulatorDemo.css";
import NavBar from "../NavBar/NavBar.js";
import ModelSimulator from "model-simulator";
import * as savage from "../../4_savage.json";
import isBrowser from '../../constants/isBrowser';

const editorData = {
  move_index: 0,
  pre_sections: savage.default.pre_sections,
  pre_comments: savage.default.pre_comments,
  models: savage.default.models,
}

let CodeMirror = null
if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
  CodeMirror = require('codemirror')
  require('codemirror/addon/selection/active-line.js')
  require('codemirror/addon/edit/matchbrackets.js')
  require('codemirror/mode/javascript/javascript.js')
}

// <link rel="stylesheet" href="lib/codemirror-5.39.0/lib/codemirror.css">
// <link rel="stylesheet" href="lib/codemirror-5.39.0/theme/darcula.css">
// <script src="lib/codemirror-5.39.0/addon/selection/active-line.js"></script>
// <script src="lib/codemirror-5.39.0/addon/edit/matchbrackets.js"></script>
// <script src="lib/codemirror-5.39.0/mode/javascript/javascript.js"></script>

export default class ModelSimulatorDemo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bpm: 100,
    }

    this.canvasRef = React.createRef();
    this.fileRef = React.createRef();
  }

  componentDidMount() {
    this.simulator = new ModelSimulator({
      canvas: this.canvasRef.current,
      pre_sections: editorData.pre_sections,
      pre_comments: editorData.pre_comments,
      models: editorData.models,
    });

    this.editor = null;
    this.createEditor(this.runCustomJsonStringify(editorData))

    dragElement(document.getElementById("codeContainer"));
  }

  runCustomJsonStringify = json => {
    return customJsonStringify(json, 0, {
      pre_sections: 1,
      pre_comments: 1,
      models: 2,
    })
  }

  createEditor = json => {
    // json = "//starting beat, increase to start in middle of walk\nlet move_index = 0;\n\n" + json;

    this.editor = CodeMirror(document.getElementById('codeContainer'), {
      value: json,
      theme: "darcula",
      lineNumbers: true,
      styleActiveLine: true,
      matchBrackets: true,
      mode: {name: "javascript", json: true},
    });

    this.timeout = null;
    this.editor.on("change", () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.updatePreview, 1000);
    });

    this.updatePreview();
  }

  //when user edits, update canvas
  updatePreview = () => {
    try {
      const results = JSON.parse(this.editor.getValue())
      //TODO input validation here
      this.simulator.move_index = results.move_index || 0;
      this.simulator.models = results.models;
      this.simulator.pre_comments = results.pre_comments;
      this.simulator.pre_sections = results.pre_sections;
      this.simulator.init();
    }
    catch(err) {
      console.log(err);
    }
  }

  readFile = e => {
    let reader = new FileReader();
    reader.readAsText(this.fileRef.current.files[0], "UTF-8");
    reader.onload = e => {
      try {
        this.editor.getDoc().setValue(this.runCustomJsonStringify(JSON.parse(e.target.result)));
      }
      catch(err) {
        alert("error" + err)
      }
    }

    reader.onerror = e => {
      alert("error reading file");
    }
  }

  resetEditorPosition() {
    document.getElementById("codeContainer").style.top = 0;
    document.getElementById("codeContainer").style.left = "";
    document.getElementById("codeContainer").style.right = 0;
  }

  render() {
    return (
      <React.Fragment>
        <NavBar title="Model Simulator Demo" githubUrl="https://github.com/harryli0088/model-simulator"/>

        <div id="preview">
          <button id="resetEditorPosition" onClick={this.resetEditorPosition}>Reset Editor Position</button>

          <canvas ref={this.canvasRef} id='canvas' width='875' height='560'></canvas>

          <div>JSON file upload: <input ref={this.fileRef} type="file" onChange={this.readFile}/></div>

          <br/>

          <div id="canvasControl">
            <button onClick={e => this.simulator.prev()}>Prev</button>
            <button onClick={e => this.simulator.next()}>Next</button>
            <span id="useArrowKeys">or use your left and right arrow keys</span>

            <div>
              Beats Per Minute <input id="bpm" type="number" value={this.state.bpm} onChange={e => this.setState({bpm: e.target.value})}/>
              <button onClick={e => this.simulator.autoStart(this.state.bpm)}>Auto Start</button>
              <button onClick={e => clearInterval(this.simulator.interval)}>Stop</button>
            </div>
          </div>
        </div>

        <div id="codeContainer" draggable>
          <div id="editorErrors"></div>
        </div>
      </React.Fragment>
    )
  }
}


function dragElement(elmnt) {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (document.getElementById(elmnt.id + "header")) {
    /* if present, the header is where you move the DIV from:*/
    document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
  } else {
    /* otherwise, move the DIV from anywhere inside the DIV:*/
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || (isBrowser && window.event);
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || (isBrowser && window.event);
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
    elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
}




function customJsonStringify(object={}, numTabs=0, expandedLevels=0) {
  function getKeyValue(key, value, numTabs, numExpandedLevels) {
    let str = getThisManyStrs(`\t`, numTabs) //start with tabs
    if(key!==undefined && key!==null) { //if there is a key (eg not an array key), use it
      str += `"${key}":`
    }

    if(typeof value === "string") {
      return str + `"${value}"` //add quotes if this is a string
    }
    else if(typeof value === "number") {
      return str + value
    }
    else if(Array.isArray(value)) {
      let addedElements = false
      str += `[` //opening bracket
      value.forEach(section => {
        addedElements = true
        str += getKeyValue(null, section, numTabs, numExpandedLevels-1) + "," //get each value without any keys
      })
      if(addedElements) { //if the array is not empty
        str = str.slice(0, str.length-1) //remove last comma
      }
      str += `\n${getThisManyStrs(`\t`, numTabs)}]` //add closing bracket
      return str
    }
    else if(typeof value === "object") {
      if(numExpandedLevels > 0) { //if we still want to expand, run custom pretty print
        str += "\n" + customJsonStringify(value, numTabs+1, numExpandedLevels)
      }
      else {
        str += getOneLineObject(value, numTabs+1) //else get the object on one line
      }
    }

    return `${str}`
  }

  function getOneLineObject(object, numTabs) {
    let addedKeys = false
    let str = `\n` + getThisManyStrs(`\t`, numTabs) //get tabs
    str += `{` //opening bracket
    Object.keys(object).forEach(key => {
      addedKeys = true
      str += getKeyValue(key, object[key], 0) + ", " //get keys and values on one line
    })
    if(addedKeys) { //if the object is not empty
      str = str.slice(0, str.length-2) //remove last comma
    }
    str += `}` //closing bracket
    return str
  }

  function getThisManyStrs(str, number) {
    let string = ""
    for(let num=0; num<number; ++num) {
      string += str
    }
    return string
  }


  let addedKeys = false
  let str = `${getThisManyStrs("\t", numTabs)}{` //opening bracket
  for(let field in object) { //loop through all fields and get key values
    addedKeys = true
    const numExpandedLevels = expandedLevels[field] || expandedLevels || 0 //TODO generalize this to pass objects down
    str += "\n" + getKeyValue(field, object[field], numTabs+1, numExpandedLevels) + ","
  }
  if(addedKeys) { //if the object is not empty
    str = str.slice(0, str.length-1) //remove last comma
  }
  return str + `\n${getThisManyStrs("\t", numTabs)}}` //add closing bracket
}
